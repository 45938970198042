import type { UseFetchOptions } from 'nuxt/app'
import { defu } from 'defu'

export async function useCustomFetch<T> (url: string, options: UseFetchOptions<T> = {}) {
  const userAuth = useCookie('token')
  const router = useRouter()

  const defaults: UseFetchOptions<T> = {
    baseURL: 'https://api.integraflux.com',
    // baseURL: 'http://localhost:8082',
    // cache request
    key: url,

    headers: userAuth.value
      ? { Authorization: `Bearer ${userAuth.value}` }
      : {},

    onResponse (_ctx) {
      // debugger
      //  console.log(_ctx)
        // if(_ctx?.response._data.irregular){
        //   router.push('/perfil/configuracoes?analise='+_ctx?.response._data.observacao)
        // }
    },

    onResponseError (_ctx) {
      // console.log(_ctx)
        if(_ctx?.response?.status === 401) {
            userAuth.value = null
            router.push('/')
            return;
        }

        
      return {error: 1, message: _ctx?.response.data?.message ?? 'Error'}
    }
  }

  // for nice deep defaults, please use unjs/defu
  const params = defu(options, defaults)

  return useFetch(url, params)
}
