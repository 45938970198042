
<template>
  <ClientOnly>
    <TairoIconnavCircularMenu />
    <TairoCollapseLayout>
      <slot />
    </TairoCollapseLayout>
    <TairoPanels />
    <TairoIconnavFooter />
  </ClientOnly>
</template>
