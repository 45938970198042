/**
 * This file is used to configure the app
 *
 * If you have the "Cannot find name 'defineAppConfig'.ts(2304)" error
 * update the root tsconfig.json file to include the following:
 *
 *  "extends": "./.app/.nuxt/tsconfig.json"
 *
 */

export default defineAppConfig({
  tairo: {
    title: 'Ares Mídia',
    collapse: {
      toolbar: {
        enabled: true,
        showTitle: true,
        showNavBurger: true,
        tools: [
          {
            component: 'DemoToolbarLanguage',
          },
          {
            component: 'DemoThemeToggle',
          },
          // {
          //   component: 'DemoToolbarAccountMenu',
          // },
          
        ],
      },
      navigation: {
        enabled: true,
        header: {
          component: 'DemoCollapseNavigationHeader',
          resolve: true,
        },
        footer: {
          component: 'DemoCollapseNavigationFooter',
          resolve: true,
        },
        items: [
          {
            name: 'Inicio',
            icon: { name: 'material-symbols:dashboard', class: 'w-5 h-5' },
            to: '/dashboard',
          },
          {
            name: 'Indicações',
            icon: { name: 'solar:hand-money-broken', class: 'w-5 h-5' },
            to: '/dashboard/indicacoes',
          },
          {
            name: 'Meu Plano',
            icon: { name: 'material-symbols:account-balance-wallet', class: 'w-5 h-5' },
            to: '/dashboard/meu-plano',
          },
          {
            name: 'Comprar Créditos',
            icon: { name: 'f7:money-dollar-circle', class: 'w-5 h-5' },
            to: '/dashboard/comprar-credito',
          },
          {
            name: 'Divider',
            divider: true,
          },
          {
            name: 'Soluções',
            icon: { name: 'ph:app-window-duotone', class: 'w-5 h-5' },
            activePath: '/dashboard/modulos',
            children: [
              {
                name: 'Enviar SMS',
                to: '/dashboard/modulos/SMS',
                icon: { name: 'fa6-solid:comment-sms', class: 'w-4 h-4' },
              },
              {
                name: 'Encurtador de Link',
                to: '/dashboard/modulos/LINK',
                icon: { name: 'fa6-solid:link', class: 'w-4 h-4' },
              },
              {
                name: 'Validador de Números',
                to: '/dashboard/modulos/VALIDADOR',
                icon: { name: 'fa6-solid:person-circle-check', class: 'w-4 h-4' },
              },
              {
                name: 'Consulta Jogador',
                to: '/dashboard/modulos/JOGADOR',
                icon: { name: 'lucide:scan-eye', class: 'w-4 h-4' },
              },
              {
                name: 'Formatador de Números',
                to: '/dashboard/modulos/FORMATAR',
                icon: { name: 'ri:contacts-book-2-line', class: 'w-4 h-4' },
              },
            ]
          },
          {
            name: 'Integrações',
            icon: { name: 'carbon:ibm-cloud-pak-integration', class: 'w-5 h-5' },
            activePath: '/dashboard/integration/',
            children: [
              {
                name: 'Recuperação de Vendas',
                to: '/dashboard/integration/funnel-sms',
                icon: { name: 'carbon:continuous-integration', class: 'w-4 h-4' },
              },
              {
                name: 'API Externa',
                to: '/dashboard/integration/api-external',
                icon: { name: 'carbon:ibm-watsonx-code-assistant-for-z-refactor', class: 'w-4 h-4' },
              },
            ]
          },
          {
            name: 'Divider',
            divider: true,
          },
          {
            name: 'Configurações',
            icon: { name: 'ph:gear-six-duotone', class: 'w-5 h-5' },
            to: '/dashboard/perfil/configuracoes',
          },
          {
            name: 'Suporte',
            icon: { name: 'ic:sharp-support-agent', class: 'w-5 h-5' },
            to: '/dashboard/ticket',
          },
          {
            name: 'Sair',
            icon: { name: 'carbon:logout', class: 'w-5 h-5' },
            to: '/dashboard/sair',
          },
          // {
          //   name: 'Divider',
          //   divider: true,
          // },
          // {
          //   name: 'Administração',
          //   admin: true,
          //   icon: { name: 'clarity:administrator-solid', class: 'w-5 h-5' },
          //   activePath: '/dashboard/admin',
          //   children: [
          //     {
          //       name: 'Clientes',
          //       to: '/dashboard/admin/clients',
          //       icon: { name: 'carbon:user', class: 'w-4 h-4' },
          //     }
          //   ]
          // }
        ],
      },
    },
    iconnav: {
      footer: {
        enabled: true,
        logoSeparator: {
          component: 'TairoLogoFooter',
          props: { class: 'text-primary-500 h-15 w-15' },
        },
        logo: {
          component: '',
          props: {
            class:
              'text-muted-300 ltablet:mx-0 mx-auto h-5 transition-all duration-200 lg:mx-0',
          },
        },
        copyright: {
          name: 'Integra Flux',
          to: 'https://integraflux.com',
          since: '2021',
        },
        links: [
          {
            name: 'LGPD',
            to: 'https://www.privacidade.com.br/portal-de-privacidade?token=d875833751f6eaff1eeba08112f3beff',
            rel: 'noopener',
            target: '_blank',
          },
          {
            name: 'API',
            to: 'https://docs.integraflux.com',
            rel: 'noopener',
            target: '_blank',
          },
          {
            name: 'Jurídico',
            to: 'mailto:juridico@arestech.app',
            rel: 'noopener',
            target: '_blank',
          },
          {
            name: 'Suporte',
            to: 'https://wa.me/551132808396',
            rel: 'noopener',
            target: '_blank',
          },
        ],
      },
      toolbar: {
        tools: [
          {
            // Use a global component
            component: 'DemoThemeToggle',
            // Define the component props, if any
            props: {
              disableTransitions: true,
            },
          },
        ],
      },
    },
    panels: [
      {
        name: 'language',
        position: 'right',
        component: 'DemoPanelLanguage',
      },
    ],
    error: {
      logo: {
        component: 'img',
        props: {
          src: '/img/illustrations/system/404-1.svg',
          class: 'relative z-20 w-full max-w-lg mx-auto',
        },
      },
    },
  },
})
