import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAddonCollapseTransition, LazyDemoAccountBalance, LazyDemoChartAreaBalance, LazyDemoCollapseNavigationFooter, LazyDemoCollapseNavigationHeader, LazyDemoIconText, LazyDemoPanelAccount, LazyDemoPanelLanguage, LazyDemoThemeToggle, LazyDemoToolbarAccountMenu, LazyDemoToolbarLanguage, LazyDemoWidgetAccountBalance, LazyDemoWidgetTransactionCompact, LazyLeadsRelatoryPanel, LazyTokenApiPanel, LazyTairoLogo, LazyTairoLogoFooter, LazyTairoLogoIcon, LazyTairoLogoText, LazyTairoToaster, LazyTairoTocAnchor, LazyIcon, LazyIconCSS } from '#components'
const lazyGlobalComponents = [
  ["AddonCollapseTransition", LazyAddonCollapseTransition],
["DemoAccountBalance", LazyDemoAccountBalance],
["DemoChartAreaBalance", LazyDemoChartAreaBalance],
["DemoCollapseNavigationFooter", LazyDemoCollapseNavigationFooter],
["DemoCollapseNavigationHeader", LazyDemoCollapseNavigationHeader],
["DemoIconText", LazyDemoIconText],
["DemoPanelAccount", LazyDemoPanelAccount],
["DemoPanelLanguage", LazyDemoPanelLanguage],
["DemoThemeToggle", LazyDemoThemeToggle],
["DemoToolbarAccountMenu", LazyDemoToolbarAccountMenu],
["DemoToolbarLanguage", LazyDemoToolbarLanguage],
["DemoWidgetAccountBalance", LazyDemoWidgetAccountBalance],
["DemoWidgetTransactionCompact", LazyDemoWidgetTransactionCompact],
["LeadsRelatoryPanel", LazyLeadsRelatoryPanel],
["TokenApiPanel", LazyTokenApiPanel],
["TairoLogo", LazyTairoLogo],
["TairoLogoFooter", LazyTairoLogoFooter],
["TairoLogoIcon", LazyTairoLogoIcon],
["TairoLogoText", LazyTairoLogoText],
["TairoToaster", LazyTairoToaster],
["TairoTocAnchor", LazyTairoTocAnchor],
["Icon", LazyIcon],
["IconCSS", LazyIconCSS],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
