import revive_payload_client_SGkco7VM6V from "/vercel/path0/node_modules/.pnpm/nuxt@3.9.3_eslint@8.56.0_typescript@5.3.3_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_JRBwvA7Kyl from "/vercel/path0/node_modules/.pnpm/nuxt@3.9.3_eslint@8.56.0_typescript@5.3.3_vue-tsc@1.8.27/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_iycrjn0rih from "/vercel/path0/node_modules/.pnpm/nuxt@3.9.3_eslint@8.56.0_typescript@5.3.3_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_TpjM5AHtEf from "/vercel/path0/node_modules/.pnpm/nuxt@3.9.3_eslint@8.56.0_typescript@5.3.3_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_edlUB4rchx from "/vercel/path0/node_modules/.pnpm/nuxt@3.9.3_eslint@8.56.0_typescript@5.3.3_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.app/.nuxt/components.plugin.mjs";
import prefetch_client_fMjrf4HKe1 from "/vercel/path0/node_modules/.pnpm/nuxt@3.9.3_eslint@8.56.0_typescript@5.3.3_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_ZBuda2GHEx from "/vercel/path0/node_modules/.pnpm/@cssninja+nuxt-toaster@0.3.12_vue@3.4.35/node_modules/@cssninja/nuxt-toaster/dist/runtime/plugin.mjs";
import plugin_client_ijIezoaBVi from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.3.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import view_transitions_client_00xKrPDoz3 from "/vercel/path0/node_modules/.pnpm/nuxt@3.9.3_eslint@8.56.0_typescript@5.3.3_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_k8XAV4HzMN from "/vercel/path0/node_modules/.pnpm/nuxt@3.9.3_eslint@8.56.0_typescript@5.3.3_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import directives_yBPzJQtdia from "/vercel/path0/layers/tairo/plugins/directives.ts";
import mask_Tk3SUcMqzt from "/vercel/path0/.app/plugins/mask.ts";
import handler_error_kSOvGOnoxc from "/vercel/path0/.app/plugins/handler-error.ts";
export default [
  revive_payload_client_SGkco7VM6V,
  unhead_JRBwvA7Kyl,
  router_iycrjn0rih,
  payload_client_TpjM5AHtEf,
  check_outdated_build_client_edlUB4rchx,
  components_plugin_KR1HBZs4kY,
  prefetch_client_fMjrf4HKe1,
  plugin_ZBuda2GHEx,
  plugin_client_ijIezoaBVi,
  view_transitions_client_00xKrPDoz3,
  chunk_reload_client_k8XAV4HzMN,
  directives_yBPzJQtdia,
  mask_Tk3SUcMqzt,
  handler_error_kSOvGOnoxc
]